const filterRender = (val) => {
  if (!val && val !== 0) {
    return '--';
  }

  return val;
};

export function setSuppliesTable () {
  const suppliesTableCols = [
    {
      title: '商品编码（sku）',
      field: 'goodsCode',
      formatter: ({ row }) => {
        return filterRender(row.goodsCode);
      },
    },
    {
      title: '商品名称',
      field: 'goodsName',
      formatter: ({ row }) => {
        return filterRender(row.goodsName);
      },
    },
    {
      title: '规格',
      field: 'specification',
      formatter: ({ row }) => {
        return filterRender(row.specification);
      },
    },
    {
      title: '型号',
      field: 'model',
      formatter: ({ row }) => {
        return filterRender(row.model);
      },
    },
    {
      title: '所属品牌',
      field: 'brandName',
      formatter: ({ row }) => {
        return filterRender(row.brandName);
      },
    },
    {
      title: '计量单位',
      field: 'unitOfMsment',
      formatter: ({ row }) => {
        return filterRender(row.unitOfMsment);
      },
    },
    {
      title: '销售数量',
      field: 'orderNum',
      formatter: ({ row }) => {
        return filterRender(row.orderNum);
      },
    },
    {
      title: '销售单价（含税）',
      field: 'unitPrice',
      formatter: ({ row }) => {
        return filterRender(row.unitPrice);
      },
    },
    {
      title: '生产商',
      field: 'producer',
      formatter: ({ row }) => {
        return filterRender(row.producer);
      },
    },
    {
      title: '厂家编码',
      field: 'factoryCode',
      formatter: ({ row }) => {
        return filterRender(row.factoryCode);
      },
    },
    {
      title: '保修期',
      field: 'period',
      formatter: ({ row }) => {
        let ret = '--';

        if (row.period || row.period === 0) {
          ret = `${row.period}${row.periodUnit}`;
        }

        return ret;
      },
    },
    {
      title: '是否医疗件',
      field: 'isMedichair',
      formatter: ({ row }) => {
        return row['isMedichair'] === 1 ? '是' : '否';
      },
    },
    {
      title: '产品注册证/备案凭证',
      field: 'regNo',
      formatter: ({ row }) => {
        return filterRender(row.regNo);
      },
    },
  ];

  return suppliesTableCols;
}
