import { setSaleContractTable } from './saleContractConfig';
import { setSuppliesTable } from './saleContractDetailConfig';

export default function setTableCols (type = 'saleContract') {
  const configs = {
    'saleContract': setSaleContractTable.call(this),
    'suppliesOfDetail': setSuppliesTable.call(this),
  };

  configs[type].forEach(v => {
    if (!v.minWidth) {
      let title = v.label || v.title;

      v.minWidth = `${title.length * 16 + (v.sortable ? 30 : 20)}px`;
    }

    v.tooltip = true;
  });

  return configs[type];
}
