export const executionStatusGroup = [
  {
    label: '全部',
    value: '',
    en: 'allNum',
    count: 0,
  },
  {
    label: '待供应商生效确认',
    value: '6',
    en: 'supPendingAuditNum',
    count: 0,
  },
  {
    label: '供应商生效驳回',
    value: '7',
    en: 'supEffectRejectNum',
    count: 0,
  },
  {
    label: '执行中',
    value: '3',
    en: 'runningNum',
    count: 0,
  },
  {
    label: '已完结',
    value: '4',
    en: 'endNum',
    count: 0,
  },
  {
    label: '已取消',
    value: '5',
    en: 'cancelledNum',
    count: 0,
  },
];

export const collectionStatusOptions = [
  {
    label: '待收款',
    value: 1,
  },
  {
    label: '部分收款',
    value: 2,
  },
  {
    label: '已收款',
    value: 3,
  },
];

export const deliveryStatusOptions = [
  {
    label: '待发货',
    value: 0,
  },
  {
    label: '部分发货',
    value: 1,
  },
  {
    label: '已发货',
    value: 2,
  },
];

export const changeStatusOptions = [
  {
    label: '无',
    value: 0,
  },
  {
    label: '待变更确认',
    value: 1,
  },
  {
    label: '变更驳回',
    value: 2,
  },
  {
    label: '已变更',
    value: 3,
  },
];

export const billingStatusOptions = [
  {
    label: '待开票',
    value: 1,
  },
  {
    label: '部分开票',
    value: 2,
  },
  {
    label: '已开票',
    value: 3,
  },
];
