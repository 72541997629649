import {
  executionStatusGroup,
  collectionStatusOptions,
  deliveryStatusOptions,
  changeStatusOptions,
  billingStatusOptions,
} from '@/model/saleContract';

function convertToName (enums, value) {
  let label = '--';

  for (let v of enums) {
    if (Number(v.value) === Number(value)) {
      label = v.label;
      break;
    }
  }

  return label;
}

export function setSaleContractTable () {
  const saleContractTableCols = [
    {
      label: '合同状态',
      prop: 'state',
      minWidth: 120,
      render: (h, { row }) => {
        return h('span', convertToName(executionStatusGroup, row.state));
      },
    },
    {
      label: '合同编号',
      prop: 'pcCode',
      minWidth: 120,

    },
    {
      label: '客户抬头',
      prop: 'unit',
      minWidth: 180,
    },
    {
      label: '合同签订日期',
      prop: 'signDate',
    },
    {
      label: '客户联系人',
      prop: 'saleContractCustomerContact',
    },
    {
      label: '收货信息',
      prop: 'receivingName',
      minWidth: '220px',
      render: (h, { row }) => {
        let info = row.saleContractReceiverResDTO || {};
        return h('span', `${info.name || ''}-${info.phone || ''}-${info.provinceName || ''}${info.cityName || ''}${info.areaName || ''}${info.address || ''}` || '--');
      },
    },
    {
      label: '创建时间',
      prop: 'createTime',
      minWidth: 150,
    },
    {
      label: '收款状态',
      prop: 'payState',
      render: (h, { row }) => {
        return h('span', convertToName(collectionStatusOptions, row.payState));
      },
    },
    {
      label: '发货状态',
      prop: 'receivingSts',
      render: (h, { row }) => {
        return h('span', convertToName(deliveryStatusOptions, row.receivingSts));
      },
    },
    {
      label: '开票状态',
      prop: 'billingState',
      render: (h, { row }) => {
        return h('span', convertToName(billingStatusOptions, row.billingState));
      },
    },
    {
      label: '变更状态',
      prop: 'changeState',
      render: (h, { row }) => {
        return h('span', convertToName(changeStatusOptions, row.changeState));
      },
    },
    {
      label: '更新时间',
      prop: 'lastUpdateTime',
      minWidth: 150,
    },
    {
      label: '操作',
      minWidth: '80px',
      render: (h, { row }) => {
        let actions = [h('span', {
          class: 'contract-option',
          on: {
            click: () => {
              this.operate('detail', row);
            },
          },
        }, '查看')];

        if (row.state === 6) { // 6-> 待供应商生效确认
          actions.push(h('span', {
            class: 'contract-option',
            on: {
              click: () => {
                this.operate('review', row);
              },
            },
          }, '审核'));
        }

        if (row.changeState === 1) { // 1 -> 待变更确认
          actions.push(h('span', {
            class: 'contract-option',
            on: {
              click: () => {
                this.operate('change', row);
              },
            },
          }, '变更审核'));
        }

        return actions;
      },
    },
  ];

  return saleContractTableCols;
}
